import React, { useContext } from 'react';
import {
  AboutCampaignX,
  CompeteAndCollaborate,
  GameOverview,
  HomePageAndRulesHero,
  HowItWorks,
  Inspiration,
  OverallBenefits,
  OverviewOrRulesVideo,
  RenderWhenReady,
  SEO,
  TheResult,
  Video
} from 'components';
import { GameDataContext } from 'context';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import getSlugifiedGameTitle from 'utils/getSlugifiedGameTitle';
import queryString from 'query-string';

const Home = ({
  data,
  location,
  user,
  isWelcomeVideoVisible,
  initialClientRender,
  scrollToAnchor,
  game,
  viewportWidth
}) => (
  <Wrapper>
    <SEO pageSpecificTitle="Home" pageSpecificDescription="Campaign X is a 10-step marketing strategy challenge, where the players (your team) are required to develop a best in class, commercially ready campaign, that they will launch together." />
    <HomePageAndRulesHero
      h1Text="Team powered marketing transformation online"
      h3Text="Welcome to Campaign X"
      scrollToAnchor={scrollToAnchor}
      initialClientRender={initialClientRender}
    />
    <div id="welcome" />
    {(isWelcomeVideoVisible || (user && game)) &&
      game.title !== 'demo' &&
      getWelcomeVideoAndZoomLink(location, user, game, data, viewportWidth)}
    <div id="find-out-more" />
    {((!user && !game && !isWelcomeVideoVisible) || (game && game.title === 'demo')) && (
      <OverviewOrRulesVideo
        pathname={location.pathname}
        viewportWidth={viewportWidth}
        videoCode="436379968"
      />
    )}
    <InnerWrapper>
      <AboutCampaignX />
      <TheResult viewportWidth={viewportWidth} />
      <OverallBenefits viewportWidth={viewportWidth} />
    </InnerWrapper>
    <HowItWorks viewportWidth={viewportWidth} />
    <InnerWrapper>
      <RenderWhenReady>
        <GameOverview />
      </RenderWhenReady>
    </InnerWrapper>
    <CompeteAndCollaborate viewportWidth={viewportWidth} />
    <Inspiration
      img={data.confucius}
      author="Confucius"
      quote="Tell me and I may forget<br />Show me and I may remember<br />Involve me and I will understand and be committed"
      quoteSmallFontSize="1.4rem"
      quoteLargeFontSize="2.55rem"
      maxWidth="36.875em"
      viewportWidth={viewportWidth}
    />
  </Wrapper>
);

const getWelcomeVideoAndZoomLink = (location, user, game, data, viewportWidth) => {
  const { gameData } = useContext(GameDataContext);
  const queryStringValue = queryString.parse(location.search).game;
  let _welcomeVideoZoomLink;
  let _liveStreamCode;
  let _hideLiveStreamAfterLaunch;

  /* Fuck me, this is some horrible code! (The kind of shite you end up writing when your boss asks
  for major functionality changes 24 hrs before launch!) */
  if (queryStringValue) {
    const {
      welcomeVideoZoomLink,
      liveStreamCode,
      hideLiveStreamAfterLaunch
    } = gameData.edges.filter(({ node }) => {
      const slugifiedGameTitle = getSlugifiedGameTitle(node.childMarkdownRemark.frontmatter.title);
      // console.log(`slug: ${slugifiedGameTitle}`);
      return slugifiedGameTitle === queryStringValue;
    })[0].node.childMarkdownRemark.frontmatter;
    _welcomeVideoZoomLink = welcomeVideoZoomLink;
    _liveStreamCode = liveStreamCode;
    _hideLiveStreamAfterLaunch = hideLiveStreamAfterLaunch;
  } else if (user && game) {
    const { welcomeVideoZoomLink, liveStreamCode, hideLiveStreamAfterLaunch } = game;
    _welcomeVideoZoomLink = welcomeVideoZoomLink;
    _liveStreamCode = liveStreamCode;
    _hideLiveStreamAfterLaunch = hideLiveStreamAfterLaunch;
  }

  return (
    <Welcome>
      <h3>Intro To{viewportWidth < 768 ? <br /> : ' '}Campaign X</h3>
      <p>Let&apos;s get this party started</p>
      {_hideLiveStreamAfterLaunch ? (
        <LiveStreamHasFinished
          fluid={data.liveStreamHasFinished.childImageSharp.fluid}
          alt="livestream finished"
        />
      ) : (
        <VideoWrapper>
          <Video videoCode={_liveStreamCode} />
        </VideoWrapper>
      )}
      <h3>Team Meeting</h3>
      <p>Don&apos;t forget to join your team meeting at the end of the livestream</p>
      <a href={_welcomeVideoZoomLink} target="__blank">
        Join The Game Action Here
      </a>
    </Welcome>
  );
};

const Wrapper = styled.div``;

const VideoWrapper = styled.div`
  max-width: 58.75em;
  width: 100%;
  margin: 0 auto;
`;

const Welcome = styled.section`
  background-color: var(--cx-dark-green);
  height: 76.25rem;
  padding: 7em 1em 0;
  width: 100%;

  h3 {
    text-align: center;

    &:nth-of-type(1) {
      color: #fff;
      font-size: 2rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 0.2em;
    }

    &:nth-of-type(2) {
      color: #000;
      font-size: 3rem;
      margin-top: 2.8325em;
      margin-bottom: 0.125em;
    }
  }

  p {
    color: #000;
    text-align: center;
    font-weight: 700;

    &:nth-of-type(1) {
      font-size: 3rem;
      margin-bottom: 1.2em;
    }

    &:nth-of-type(2) {
      font-size: 2rem;
      color: #fff;
    }
  }

  a {
    align-items: center;
    background-color: var(--cx-beige);
    border-radius: 0.125em;
    color: #000;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    height: 2.875em;
    justify-content: center;
    margin: 1.7em auto 0;
    text-decoration: none;
    text-transform: uppercase;
    width: 17.5em;
  }
`;

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 2.66%;

  @media screen and (min-width: 28.125em) {
    margin: 0 9.1%;
  }
`;

const LiveStreamHasFinished = styled(Img)`
  max-width: 58.75em;
  width: 100%;
  margin: 0 auto;
`;

export const HomePageQuery = graphql`
  {
    gameData: allFile(filter: { sourceInstanceName: { eq: "gameData" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
            }
          }
        }
      }
    }
    liveStreamHasFinished: file(relativePath: { eq: "Home/livestream_finished.png" }) {
      childImageSharp {
        fluid(maxWidth: 940) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    confucius: file(relativePath: { eq: "Shared/Inspiration/confucius.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 590, maxHeight: 620) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Home;
